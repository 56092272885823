import React, { useState } from "react";
import emailjs from "@emailjs/browser";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [statusMessage, setStatusMessage] = useState("");
  const [loading, setLoading] = useState(false); // Indicateur d'envoi

  // Validation des entrées utilisateur
  const validateInputs = () => {
    const { name, email, phone, subject, message } = formData;

    // Vérifier que tous les champs sont remplis
    if (!name || !email || !phone || !subject || !message) {
      setStatusMessage("Tous les champs sont obligatoires.");
      return false;
    }

    // Vérifier le format de l'email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setStatusMessage("Veuillez entrer une adresse email valide.");
      return false;
    }

    // Vérifier le format du téléphone (exemple pour les numéros français)
    const phoneRegex = /^\+?[0-9\s-]{10,15}$/;
    if (!phoneRegex.test(phone)) {
      setStatusMessage("Veuillez entrer un numéro de téléphone valide.");
      return false;
    }

    // Vérifier que le message n'est pas trop court
    if (message.length < 10) {
      setStatusMessage("Votre message doit contenir au moins 10 caractères.");
      return false;
    }

    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setStatusMessage(""); // Réinitialiser le message d'erreur
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Valider les entrées utilisateur
    if (!validateInputs()) return;

    setLoading(true); // Activer l'indicateur de chargement

    // Envoyer l'email avec EmailJS
    emailjs
      .send(
        "service_wxhqmim", // Votre Service ID
        "template_0sl9wx6", // Votre Template ID
        formData,
        "aoGNb2tjhzxZnt8yB" // Votre Public Key
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setStatusMessage("Votre message a été envoyé avec succès !");
          setFormData({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
          }); // Réinitialiser le formulaire
        },
        (error) => {
          console.error("FAILED...", error);
          setStatusMessage("Une erreur est survenue, veuillez réessayer.");
        }
      )
      .finally(() => {
        setLoading(false); // Désactiver l'indicateur de chargement
      });
  };

  return (
    <section className="bg-black text-white px-4" id="contact">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl md:text-5xl font-bold text-center mb-12">
          CONTACTEZ-MOI
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Colonne de gauche : Infos de contact */}
          <div className="space-y-8 flex flex-col justify-center">
            {/* Appelez-nous */}
            <div className="flex items-start space-x-4">
              <div className="bg-gray-900 p-4 flex items-center justify-center">
                <i className="fas fa-phone text-white text-xl"></i>
              </div>
              <div>
                <h3 className="font-bold">Appelez-moi</h3>
                <p className="text-gray-300 mt-1">+33 7 50 69 93 25</p>
              </div>
            </div>

            {/* Email */}
            <div className="flex items-start space-x-4">
              <div className="bg-gray-900 p-4 flex items-center justify-center">
                <i className="fas fa-envelope text-white text-xl"></i>
              </div>
              <div>
                <h3 className="font-bold">Email</h3>
                <p className="text-gray-300 mt-1">thomasdescamps19@gmail.com</p>
              </div>
            </div>
          </div>

          {/* Colonne de droite : Formulaire */}
          <form onSubmit={handleSubmit} className="space-y-4">
            <input
              type="text"
              name="name"
              placeholder="Tapez votre nom"
              value={formData.name}
              onChange={handleChange}
              className="w-full bg-black border border-gray-800 text-white py-2 px-4 focus:outline-none focus:border-red-500 transition-colors duration-300"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Tapez votre email"
              value={formData.email}
              onChange={handleChange}
              className="w-full bg-black border border-gray-800 text-white py-2 px-4 focus:outline-none focus:border-red-500 transition-colors duration-300"
              required
            />
            <input
              type="tel"
              name="phone"
              placeholder="Tapez votre téléphone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full bg-black border border-gray-800 text-white py-2 px-4 focus:outline-none focus:border-red-500 transition-colors duration-300"
              required
            />
            <input
              type="text"
              name="subject"
              placeholder="Tapez votre sujet"
              value={formData.subject}
              onChange={handleChange}
              className="w-full bg-black border border-gray-800 text-white py-2 px-4 focus:outline-none focus:border-red-500 transition-colors duration-300"
              required
            />
            <textarea
              name="message"
              placeholder="Tapez votre message"
              rows="4"
              value={formData.message}
              onChange={handleChange}
              className="w-full bg-black border border-gray-800 text-white py-2 px-4 focus:outline-none focus:border-red-500 transition-colors duration-300"
              required
            ></textarea>
            <button
              type="submit"
              className="bg-red-500 hover:bg-red-600 text-white py-2 px-6 rounded-full font-bold transition-colors duration-300"
              disabled={loading} // Désactiver le bouton pendant le chargement
            >
              {loading ? "Envoi en cours..." : "Envoyer le message"}
            </button>
            {statusMessage && (
              <p className="text-sm mt-2 text-center text-gray-300">
                {statusMessage}
              </p>
            )}
          </form>
        </div>
      </div>
    </section>
  );
}

export default Contact;
