import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHtml5,
  faWordpress,
  faReact,
  faJoomla,
  faDrupal,
  faShopify,
} from "@fortawesome/free-brands-svg-icons";

function Skills() {
  const skillsRef = useRef(null);
  const [visible, setVisible] = useState(false);

  const skills = [
    { name: "HTML & CSS", value: 100, icon: faHtml5 },
    { name: "WordPress", value: 100, icon: faWordpress },
    { name: "ReactJs", value: 100, icon: faReact },
    { name: "React Native (Mobile)", value: 100, icon: faReact },
    { name: "Joomla", value: 100, icon: faJoomla },
    { name: "Drupal", value: 100, icon: faDrupal },
    { name: "Shopify", value: 100, icon: faShopify },
  ];

  useEffect(() => {
    const currentRef = skillsRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <section
      ref={skillsRef}
      className="min-h-20 bg-black text-white flex items-center justify-center p-8"
    >
      <div className="max-w-5xl w-full">
        <h1 className="text-4xl md:text-5xl font-bold mb-8 text-center">
          Mes Compétences
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {skills.map((skill, index) => (
            <div key={index} className="space-y-2">
              <div className="flex items-center justify-between">
                <span className="font-bold">{skill.name}</span>
                {/* Icône à droite */}
                <FontAwesomeIcon
                  icon={skill.icon}
                  className="text-xl md:text-2xl text-red-500"
                />
              </div>
              <div className="w-full bg-white bg-opacity-10 h-2 relative overflow-hidden">
                <div
                  className="absolute left-0 top-0 h-2 bg-red-500 transition-all duration-1000"
                  style={{
                    width: visible ? `${skill.value}%` : "0%",
                  }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Skills;
