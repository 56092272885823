import React, { useState } from "react";

function Projects() {
  const [selectedCategory, setSelectedCategory] = useState("Tout");

  const categories = [
    { name: "Tout", color: "bg-red-500" },
    { name: "One Page", color: "bg-gray-800" },
    { name: "Vitrine", color: "bg-gray-800" },
  ];

  const projects = [
    {
      title: "FM Electrique",
      image: "/assets/fmelectrique.jpg",
      category: "One Page",
      link: "http://www.fmelectrique.fr/",
    },
    {
      title: "SF Rénovation",
      image: "/assets/sfrenovation.jpg",
      category: "Vitrine",
      link: "https://www.sfrenovation.fr",
    },
    // Ajoutez d’autres projets ici...
  ];

  const filteredProjects =
    selectedCategory === "Tout"
      ? projects
      : projects.filter((project) => project.category === selectedCategory);

  return (
    <section
      id="projects"
      className="bg-black text-white min-h-screen py-16 px-4 sm:px-8 relative"
    >
      <div className="max-w-6xl mx-auto text-center mb-10">
        <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold">
          PROJETS RÉCENT
        </h1>
      </div>

      {/* Catégories */}
      <div className="flex flex-wrap justify-center space-x-2 sm:space-x-4 mb-10">
        {categories.map((cat) => (
          <button
            key={cat.name}
            onClick={() => setSelectedCategory(cat.name)}
            className={`px-3 py-1 sm:px-4 sm:py-2 rounded-full text-white transition-colors duration-300 flex items-center space-x-1 sm:space-x-2 mb-2 
            ${selectedCategory === cat.name ? "bg-red-500" : "bg-gray-800"} 
            hover:bg-red-500`}
          >
            {selectedCategory === cat.name ? null : (
              <span className="text-base sm:text-xl">-</span>
            )}
            <span className="text-sm sm:text-base">{cat.name}</span>
            {selectedCategory === cat.name ? null : (
              <span className="text-base sm:text-xl">-</span>
            )}
          </button>
        ))}
      </div>

      {/* Grille de projets */}
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {filteredProjects.map((project, index) => (
          <div
            key={index}
            className="bg-white p-4 flex flex-col items-center justify-center space-y-4"
          >
            <img
              src={project.image}
              alt={project.title}
              className="object-contain max-h-48 w-auto"
            />
            <a
              href={project.link}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition-colors text-sm sm:text-base"
            >
              Voir le site
            </a>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Projects;
