import React from "react";
import { Helmet } from "react-helmet";

function About() {
  return (
    <>
      <Helmet>
        <title>À propos de Thomas Descamps - Concepteur Web</title>
        <meta
          name="description"
          content="Découvrez le parcours, les valeurs et les compétences de Thomas Descamps, concepteur web expert en ReactJs et Wordpress à Tourcoing. Téléchargez son CV et contactez-le."
        />
      </Helmet>

      <main
        id="about"
        className="min-h-screen bg-black text-white flex flex-col justify-center items-center py-16 px-8"
      >
        <h1 className="text-4xl md:text-6xl font-bold mb-8 text-center">
          À PROPOS DE MOI
        </h1>

        <div className="max-w-6xl w-full flex flex-col md:flex-row items-center md:items-start md:space-x-8">
          {/* Colonne de gauche : Image */}
          <div className="md:w-1/2 flex justify-center mb-8 md:mb-0">
            <img
              src="/assets/moi.png"
              alt="Thomas Descamps en compagnie de son chien, illustrant une ambiance conviviale"
              className="max-w-xs md:max-w-sm object-contain"
            />
          </div>

          {/* Colonne de droite : Texte */}
          <div className="md:w-1/2 text-left space-y-6">
            <p className="text-white text-[2rem]">
              <span className="text-red-500 font-bold">
                Bienvenue sur mon profil
              </span>
            </p>

            <h2 className="text-2xl font-bold text-white mt-4">
              Qui suis-je ?
            </h2>
            <p className="text-white text-[20px]">
              Bonjour, je m'appelle{" "}
              <span className="text-red-500 font-bold">Thomas Descamps.</span>
              <br />
              Je suis concepteur web avec 2 ans d’expérience. Grâce à ma solide
              expertise en conception de sites internet, je maîtrise les
              technologies <strong>ReactJs </strong>
              et <strong>Wordpress</strong> ainsi que les processus nécessaires
              pour mener à bien un projet web de A à Z.
            </p>

            <h2 className="text-2xl font-bold text-white mt-4">
              Quelles sont mes valeurs ?
            </h2>
            <p className="text-white text-[20px]">
              Lors de la réalisation d’un projet web, je me concentre avant tout
              sur la compréhension des objectifs de mes clients afin de leur
              livrer un produit final parfaitement adapté à leurs attentes. Mon
              approche est basée sur l’écoute, la réactivité et la satisfaction
              client.
            </p>

            <p className="text-white">
              <span className="font-bold">Téléphone :</span> 07 50 69 93 25
              <br />
              <span className="font-bold">E-mail :</span>{" "}
              thomasdescamps19@gmail.com
            </p>

            {/* Lien de téléchargement du CV */}
            <a
              href="/assets/ThomasDescampsCV.pdf"
              download="ThomasDescampsCV.pdf"
              className="inline-block bg-red-500 hover:bg-red-600 transition-colors duration-300 text-white px-4 py-2 rounded"
            >
              Télécharger Mon CV
            </a>
          </div>
        </div>
      </main>
    </>
  );
}

export default About;
